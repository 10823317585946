/* DataStorageService.css */
.error-container {
  border: 1px solid red;
  padding: 10px;
  margin-bottom: 1rem;
  position: relative;
  justify-content: center;
}

.error-close-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  background: transparent;
  border: none;
  color: red;
  font-size: 1.2rem;
  cursor: pointer;
}

.empty-message {
  text-align: center;
  font-size: 1.1rem;
  color: #777;
  padding: 2rem;
  border: 1px dashed #ccc;
  margin-top: 1rem;
  border-radius: 4px;
}
