/* Container for the entire preferences page */
.container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 1rem;
}

/* Page title */
.container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
}

/* Instructional text */
.container p {
  text-align: center;
  color: #555;
  margin-bottom: 1rem;
}

/* Style for the "Select All" row */
.data-storage-select-menu {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
}

/* Individual selection row styling */
.checkbox-row {
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.checkbox-row:hover {
  background-color: #e6f7ff;
}

/* Ensure checkbox and label text are aligned */
.checkbox-row label {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-row input[type='checkbox'] {
  margin-right: 10px;
}

/* Style for the Save Preferences button */
.btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-align: center;
}

.btn:hover {
  background-color: #0056b3;
}
