*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: 'Rubik', sans-serif;
  position: relative;
  margin: 0;
  min-height: 100%;
  padding-bottom: 0;
  /* background: #d9dee9; */
}

main {
  padding-bottom: 150px;
}

h1 {
  font-size: 2.2em;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

input,
select,
textarea {
  font-size: 16px;
}

.select-menu-short {
  width: auto;
  outline: none;
  height: 35px;
  margin-bottom: 20px;
  border-radius: 0px;
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: solid 2px blue;
}

.select-menu {
}

textarea {
  font-family: 'Rubik', sans-serif;
}

button {
  cursor: pointer;
}

.link-button {
  background: none;
  color: blue;
  border: none;
  padding: 0;
  font: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.link-button:hover {
  text-decoration: none;
}

.icon-btn-no-background {
  font-size: 2em;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  background-color: transparent;
}

.centered-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.centered-text {
  text-align: center;
}

.btn {
  display: inline-block;
  min-width: 180px;
  margin: 10px 10px 20px 10px;
  /* background: blue; */
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 1em;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  outline: none;
  background: #012c7a;
  background-image: -webkit-linear-gradient(top, #012c7a, #4a81e6);
  background-image: -moz-linear-gradient(top, #012c7a, #4a81e6);
  background-image: -ms-linear-gradient(top, #012c7a, #4a81e6);
  background-image: -o-linear-gradient(top, #012c7a, #4a81e6);
  background-image: linear-gradient(to bottom, #012c7a, #4a81e6);
}

.btn:hover {
  background: #0694c7;
  background-image: -webkit-linear-gradient(top, #0694c7, #8fc0e0);
  background-image: -moz-linear-gradient(top, #0694c7, #8fc0e0);
  background-image: -ms-linear-gradient(top, #0694c7, #8fc0e0);
  background-image: -o-linear-gradient(top, #0694c7, #8fc0e0);
  background-image: linear-gradient(to bottom, #0694c7, #8fc0e0);
  /* text-decoration: none;*/
  color: black;
  color: white;
}

/* .btn:hover, */
.btn:disabled {
  background: #0694c7;
  /* background-image: -webkit-linear-gradient(top, #0694c7, #8fc0e0);
  background-image: -moz-linear-gradient(top, #0694c7, #8fc0e0);
  background-image: -ms-linear-gradient(top, #0694c7, #8fc0e0);
  background-image: -o-linear-gradient(top, #0694c7, #8fc0e0); */
  background-image: linear-gradient(to bottom, #c8cccf, #f7f8f8);
  /* text-decoration: none; */
  color: rgb(116, 216, 213);
  /* border: solid 1px rgb(146, 219, 216); */
}

.centered-btn-wrapper {
  text-align: center;
}

.simple-btn {
  font-size: 1em;
}

.form-input {
  width: 100%;
  height: 40px;
  margin: 10px 0px 10px 0px;
  border: solid 1px lightgrey;
  outline: 0;
  box-shadow: none;
  padding-left: 8px;
}

.form-input-textarea {
  width: 100%;
  height: 50px;
  margin: 5px 0px 5px 0px;
  border: solid 1px lightgrey;
  outline: 0;
  box-shadow: none;
  padding-left: 8px;
}

form label,
form legend {
  color: blue;
  margin-left: 5px;
}

form .btn {
  margin-top: 5px;
}

.form-button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the value to get the desired spacing */
}

form .form-error {
  margin-top: 0px;
  padding-left: 15px;
  padding-bottom: 0px;
  /*visibility: hidden;*/
  color: red;
  font-size: 18px;
}

.success-message {
  color: green;
  margin-top: 20px;
}

.error-message {
  color: red;
  margin-top: 20px;
}

.spinnerModal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 1;
  /* Sit on top */
  overflow: auto;
  background: url('./images/ajax-indicator-circle-ball-blue.gif') no-repeat
    center fixed;
  background-color: rgba(255, 255, 255, 0.3);
  /* Create overlay - black w/ opacity */
}

/*Creates an heartbeat animation*/
@keyframes heartbeat-animation {
  0% {
    transform: scale(0.75);
  }

  20% {
    transform: scale(1.5);
  }

  40% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(1.5);
  }

  80% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(0.75);
  }
}

.spinnerModal p {
  margin-top: 300px;
}

.dropdown {
  display: inline-block;
  position: absolute;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  overflow: auto;
  background-color: #f6f6f6;
  z-index: 1000 !important;
  cursor: pointer;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content-open {
  display: block;
}

.dropdown-content a {
  display: block;
  color: #000000;
  padding: 5px;
  font-size: 0.7em;
  white-space: nowrap;
  text-decoration: none;
}

.dropdown-content a:hover {
  color: #ffffff;
  background-color: #558cf1;
}

.go_back-button {
  color: blue;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 0px;
  outline: none;
}

.go_back-arrow {
  color: blue;
  font-size: 20px;
  outline: none;
}

.clickable-title {
  font-size: 1.1em;
  margin-top: 5px;
  /* margin-left: 30px; */
}

textarea {
  min-height: 10px;
}

.flex-title-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-title-icon-item {
  flex: initial;
  margin-right: 20px;
}

.flex-title-icon > span {
  font-size: 1.3em;
  margin-top: 6px;
}

@media screen and (min-width: 600px) {
  h1 {
    font-size: 2.5em;
  }

  .centered-container.narrow {
    max-width: 450px;
  }

  .centered-container {
    width: 60%;
  }

  .centered-container.max {
    width: 95%;
  }

  .search-wrapper input[type='text'] {
    width: 70%;
  }

  form .btn {
    margin-top: 20px;
  }

  .dropdown-content a {
    font-size: 1em;
  }
}
